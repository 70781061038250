<template>
    <front-layout>
        <v-container style="min-height: calc(100vh - 228px)">
            <v-row>
                <v-col md="9">
                    <filter-projects-page @search="searchProjects" />

                    <loading-grid v-if="isLoading" />

                    <!--<v-row class="text-center" v-if="projects.length>0">
                        <v-col>
                            <open-street-map-multi-marker
                                :lat="40.183565"
                                :lng="44.514343"
                                zoom="14"
                                id="main-map"
                                :markers="projects"
                                height="500"

                            />
                        </v-col>
                    </v-row>-->

                    <v-row v-if="projects.length" class="text-center">
                        <v-col v-for="project in projects" :key="project.id" md="6" lg="4" xl="3">
                            <project-card :project="project" />
                        </v-col>
                    </v-row>
                    <v-row v-else-if="!isLoading" class="text-center">
                        <v-col>
                            <h4>No results.</h4>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col md="3">
                    <advertising :limit="3" />
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import City from '../../../models/City'
import Developer from '../../../models/Developer'

export default {
    name: "projects.index",

    components: {
        LoadingGrid: () => import('@/components/LoadingGrid'),
        FrontLayout: () => import('@/layouts/FrontLayout'),
        ProjectCard: () => import('@/components/cards/ProjectCard'),
        FilterProjectsPage: () => import('@/components/filter/FilterProjectsPage'),
        Advertising: () => import('@/components/ads/Advertising'),
        // OpenStreetMapMultiMarker: () => import('@/components/OpenStreetMapMultiMarker'),
    },
    data() {
        return {
            title: this.$t('meta.projects_title'),
            description: this.$t('meta.projects_description'),
            developer: null,
            city: null,
            city_name: '',
            developer_name: ''
        }
    },
    metaInfo() {
        return {
            title: this.title,
            meta: [
                { name: 'description', content: this.description }
            ]
        }
    },
    computed: {
        ...mapGetters({
            isLoading: 'project/loading',
            projects: 'project/projects',
            filter: 'project/filter',
        }),
        queryTimestamp() {
            return this.objectValuesLength(this.filter) ? new Date().getTime() : undefined
        }
    },

    mounted() {
        this.setFilter()
        this.getProjects()
        this.setFilterToQuery()
    },

    methods: {
        getProjects() {
            this.$store.dispatch('project/fetchProjects')
        },
        async setMeta(query) {
            let developer = query?.developer
            let location = query?.location
            let completion = query?.completion
            this.developer = null
            this.city = null
            this.city_name = ''
            this.developer_name = ''

            if(developer){
                let _developer = await Developer.custom('/developer/slug/' + developer).get()
                this.developer = _developer[0]
                this.setDeveloperName()
            }

            if(location){
                let _city = await City.custom('/city/slug/' + location).get()
                this.city = _city[0]
                this.setCityName()
            }

            this.title = this.$t('meta.projects_with_location_title',{city_name:this.city_name,developer_name:this.developer_name,year:completion})
            this.description = this.$t('meta.projects_with_location_description',{city_name:this.city_name,developer_name:this.developer_name,year:completion})

        },
        setDeveloperName(){
            if(this.$i18n.locale === 'ru'){
                this.developer_name = this.developer.name_ru
            }else if(this.$i18n.locale === 'en'){
                this.developer_name = this.developer.name_en
            }else {
                this.developer_name = this.developer.name_hy
            }
        },
        setCityName(){
            if(this.$i18n.locale === 'ru'){
                this.city_name = this.city.name_ru
            }else if(this.$i18n.locale === 'en'){
                this.city_name = this.city.name_en
            }else {
                this.city_name = this.city.name_hy
            }
        },
        searchProjects() {
            this.setFilterToQuery()
            this.getProjects()
        },
        setFilter() {
            if (this.objectValuesLength(this.$route.query)) {
                this.setFilterFromQuery()
            } else if (this.objectValuesLength(this.filter)) {
                this.setFilterToQuery()
            }
        },
        setFilterFromQuery() {
            this.$store.dispatch('project/setFilter', { ...this.$route.query })
        },
        setFilterToQuery() {
            this.$router.replace({
                name: 'projects.index',
                query: { ...this.filter, t: this.queryTimestamp }
            })
            this.setMeta(this.$route.query)
        },
        objectValuesLength(obj = {}) {
            return Object.values(obj).filter(key => key).length
        }
    },
    beforeDestroy() {
        this.$store.dispatch('project/resetDevelopers')
        this.$store.dispatch('project/resetProjects')
    }
}
</script>
